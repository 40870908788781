@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;500;600;700;800&display=swap');

body {
    overflow-x: hidden !important;
    width: 100% !important;
}

h2,
h3,
h4,
h5 {
    font-family: 'EB Garamond', serif !important;
    font-weight: 400 !important;
    line-height: 1.3 !important;
}

.search-field h2 {
    text-align: center !important;
    width: 100%;
}

p {
    font-family: 'EB Garamond', serif !important;
    font-weight: 400;
    line-height: 1.6 !important;
    font-size: 12pt;
}

.min-margin {
    margin-left: -15px !important;
}

.nav-wrapper {
    background-color: #171717 !important;
}

.main-content-container,
body,
.main-content,
.container-fluid,
#root {
    background-color: white !important;

}

.review-block h3 {
    margin-top: 5%;
}

.aling-right {
    float: right;
}

.review-block h5 {
    margin-bottom: 8%;
}

.vactions-col h3 {
    color: white;
}

.vactions-col ul {
    padding-left: 0%;
}

.vactions-col ul li {
    list-style: none;
    color: white;
    padding-bottom: 2%;
    padding-top: 2%;
    border-bottom: solid 1px white;
    width: 70%;
}

.review-block h3,
.review-block p,
.review-block h5 {
    text-align: center;

}

.kwart {
    width: 28% !important;
    float: left;
    margin-right: 2%;
}

.eenkwart {
    width: 20% !important;
    float: left;

}



.overview-order {
    background-color: #2C718F;
    width: 100%;
    border-radius: 5px;
    margin-left: -5% !important;
}

.overview-order tr td {
    padding: 3% 3%;
    color: white;
    border-bottom: solid white 1px;
}

.frontend-form {
    padding-bottom: 10%;
}

.total-backend-order {
    border-bottom: none !important;
}

.frontend-form h2 {
    color: black;
    font-size: 16pt;
}

.submit-button {
    width: 50%;
    padding: 2%;
    background-color: #2C718F;
    border-radius: 5px;
    border: none;
    color: white;
    margin-left: 50%;
}

.submit-button:hover {
    background-color: #245f79;
}


.backend {
    background-color: #262525 !important;
}

.main-navbar .navbar {
    background-color: #171717 !important;
}

.right-menu-header {
    text-align: left !important;
}

/* settings input fields */

.align-right input {
    width: 100%;
    -webkit-appearance: none;
}

/* form style */
.data-section input[type=text],
.data-section input[type=file],
.data-section input[type=email],
.data-section input[type=phone],
.data-section input[type=date],
.data-section input[type=number],
.data-section select {
    width: 100%;
    padding: 1% 2%;
    border-radius: 50px;
    margin-bottom: 2%;
    border: none;
    background-color: #304B7B;
    color: white;
    -webkit-appearance: none;
}

.error-message {
    float: left;
    margin-top: 2%;
    margin-left: 20%;
    margin-bottom: 10%;
    color: white;
    text-align: center;
    padding: 2% 3% !important;
}



.main-label {
    font-weight: 800;
}


input[type=submit] {
    padding: 1% 2%;
    background-color: #00AEEF !important;
    border-radius: 50px;
    width: 20%;
    color: white;
    border: none;
    -webkit-appearance: none;

    font-family: 'Quicksand', sans-serif !important;
}

.picoo-button-next a,
.picoo-button-next button {
    padding: 2% 4%;
    background-color: #304B7B !important;
    border-radius: 50px;
    margin-top: 5%;
    width: 50%;
    margin-left: 20%;
    color: white;

    text-align: center;
    border: none;
    font-size: 17px;
    font-family: 'Quicksand', sans-serif !important;
}

.picoo-button-next a:hover,
.picoo-button-next button:hover {
    opacity: 0.6;
    color: white;
    text-decoration: none;
}


/* page about the settings of the application */
.button-settings {
    background-color: white;
    padding: 1% 1%;
    width: 100%;
    border-color: #f0f0f0;
    font-weight: 700;
    border-style: solid;
    border-width: 1px;
    text-align: center;
    -webkit-box-shadow: 4px 4px 16px -6px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 4px 4px 16px -6px rgba(0, 0, 0, 0.75);
    box-shadow: 4px 4px 16px -6px rgba(0, 0, 0, 0.75);

}

.button-settings:hover,
.button-settings:hover a {
    background-color: #5EBDE9;
    color: white;
    cursor: pointer;
}

.button-settings a {
    color: #334B5F;
    text-decoration: none;
    display: block;
    height: 100%;
    width: 100%;
}

.left {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}



.right {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;

}

.active-settings {
    background-color: #5EBDE9;
    border-color: #5EBDE9;
    color: #5EBDE9 !important;
}

.active-settings a {
    color: #5EBDE9;
}

.settings-table {
    width: 100%;
}

.settings-table tr th {
    padding: 1% 2%;
}

.settings-table tr td {
    background-color: white;
    padding: 1% 2%;
    width: 25%;
}

.settings-table tr:first-child {
    border-bottom-color: gray;
    border-bottom-style: none;
    border-bottom-width: 1px;
}

.settings-table tr {
    border-bottom-color: gray;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    box-shadow: 0 9px 0px 0px white, 0 -9px 0px 0px white, 12px 0 15px -4px rgba(31, 73, 125, 0.8), -12px;
}


.settings-table tr:not(:first-child),
.settings-table .end-of-table {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.settings-table tr:nth-child(2) td:nth-child(1) {
    border-top-left-radius: 5px !important;
    padding: 2% 2% 1% 2%;
}

.settings-table tr:nth-child(2) td:nth-child(2),
.settings-table tr:nth-child(2) td:nth-child(3),
.settings-table tr:nth-child(2) td:nth-child(4),
.settings-table tr:nth-child(2) td:nth-child(5),
.settings-table tr:nth-child(2) td:nth-child(6) {
    border-top-left-radius: 0px !important;

}

.settings-table tr:nth-child(2) td:nth-child(6) {
    border-top-right-radius: 5px !important;
}

.end-of-table {
    background-color: white;
    width: 100%;
    text-align: center;
    padding: 1% 2% 2% 2%;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    font-weight: 800;
}

.small-settings-section {
    background-color: white;
    border-radius: 5px;
    padding-top: 2%;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    border-style: solid;
    border-width: 1px;
    border-color: #70707038;
    width: 100%;
}

.small-settings-section li {
    list-style: none;
    padding: 2% 4% 3% 4%;
    border-bottom-style: solid;
    border-bottom-color: #70707038;
    border-bottom-width: 1px;
}


.align-left {
    text-align: left;
    width: 20%;
}

.align-left-button {
    float: left !important;
    ;
}

.align-right {
    float: right;
    width: 65%;
}

.settings-div {
    padding: 6% 4%;
}

.recover-button {
    text-decoration: underline;
}


.save-button {
    float: right;
    padding: 1.5% 12%;
    background-color: #5EBDE9;
    border-radius: 50px;
    margin-top: -4%;
    color: white;
    font-weight: 700;
}

.save-button:hover {
    background-color: #334B5F;
}

.separator-column {
    padding: 1%;
}

.svg-icon {
    width: 7%;
    margin-right: 8%;
    float: left;
}

.margin-top {
    margin-top: 5%;

}

.sub-page .header-section {
    background-color: #2C718F;
    position: relative;
    width: 109%;
    margin-left: -4%;
    padding: 0%;
    padding-top: 3%;
    margin-bottom: 0%;
    margin-top: 0%;
    z-index: 999;
}

.sub-page .logo-section img {
    width: 40%;
    margin-left: 30%;
    margin-top: -25%;
    padding-bottom: 5%;
}

.header-section {
    position: absolute;
    width: 109%;
    margin-left: -4%;
    padding: 3%;
    margin-bottom: 4%;
    margin-top: 4%;
    z-index: 999;
}

.footer-section {
    background-color: #27718F;
    width: 109%;
    margin-left: -2%;
    padding: 5% 5%;
    padding-left: 5%;
    padding-bottom: 0%;

}

.footer-section ul {
    padding-left: 0%;
}

.footer-section ul li {
    list-style: none;
    color: white;
    margin-bottom: 3%;
}

.footer-section p {
    color: white;
    padding-right: 5%;
}

.footer-section h3 {
    color: white;
}

.bottom-line hr {
    color: white;
    border-color: white;
}

.menu-section {
    text-align: right;


}

.menu-section a:hover {

    text-decoration: none;
    color: rgba(255, 255, 255, 0.685);
}




.menu-section a {
    margin-right: 3%;
    color: white;
    font-weight: 'bold';
}

.logo-section {
    background-color: #27718F !important;
    min-height: 130px;
    padding-top: 5%;
    position: relative;
    top: 0 !important;
    margin-top: -3%;
    width: 200px;
}

.logo-section img {
    width: 50%;
    margin-left: 25%;
    margin-top: -25%;
    padding-bottom: 5%;

}

.amount-people {
    font-size: 12pt;
}

.amount-people img {
    width: 3%;
    margin-right: 2%;
    margin-left: 1%;
    margin-top: -0.5%;
}

.sub-page .amount-people img {
    width: 3% !important;
}

.room-details h3 {
    margin-bottom: 4%;
    margin-top: 5%;
}




.checkout-layout {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10%;
}

.layout-back {
    background-repeat: no-repeat;
    background: url('../assets/images/checkoutimages/background-checkout.jpg') rgba(44, 113, 143, 0.5) center no-repeat !important;
    background-blend-mode: multiply;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.rooms-home h2 {
    color: white;
    text-align: center;
    margin-top: 5%;
}

.rooms-home {
    background-repeat: no-repeat;
    background: url('../assets/images/background-rooms.jpg') !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.MuiStepIcon-root.MuiStepIcon-active,
.MuiStepIcon-root.MuiStepIcon-completed {
    color: #27718F !important;
}

.MuiButton-containedPrimary {
    background-color: #27718F !important;
}

.issuer-form {
    padding-left: 5% !important;
    padding-top: 2% !important;
}


.betaalmethode p {

    float: right;
    position: absolute;
    margin-left: 100%;
    margin-top: -20%;
    font-weight: 'bold';

}

.betaalmethode img {
    float: left;
    width: 80% !important;
    position: absolute;
    margin-top: -30%;
}

.room-checkout {
    min-width: 55px !important;
}

.thank-you-page {

    margin-top: -4%;
    background: url('../assets/images/checkoutimages/background-checkout.jpg') rgba(44, 113, 143, 0.5) center no-repeat !important;
    background-blend-mode: multiply;


    background-size: cover;
    background-position: center;
}

.thank-you-page-container {
    margin-top: 5%;
    margin-bottom: 5%;
    padding: 5%;
    padding-bottom: 3%;
    background-color: white;
    border-radius: 5px;

}

.thank-you-page-container h2 {
    text-align: center;
    color: black;
}

.thank-you-page-colum h3 {
    color: black;
    font-size: 14pt;
}

.thank-you-page-colum ul {
    padding-left: 7%;
}

.thank-you-page-colum ul li {
    margin-bottom: 3%;
}

.social-icons {
    text-align: center;
    margin-top: 5%;
    margin-bottom: -4%;
}



.social-icons ul li {
    list-style: none;
    float: left;
    text-align: center;
    margin-right: 7%;
    margin-left: 7%;
    font-size: 13pt;
    color: #27718F;
}

.social-icons ul li a {
    color: #27718F;
}

.social-icons ul li:hover {
    opacity: 0.5;
}

.back-link {
    margin-top: 5%;
    text-align: right;
}

.subtile-link {
    text-decoration: underline;
    font-size: 10pt;
    color: black;
}

.subtile-link:hover {
    opacity: 0.8;
}

.dashboard-row {
    width: 100%;

    padding-left: 2%;
}

.row-dashboard {
    column-gap: 20px;
}

.dashboard-col {
    background-color: #313131;
    border-radius: 5px;
    color: white;
    padding-top: 2%;
    padding-left: 2%;
}

.dashboard-col img {
    float: left;
    width: 25%;
}

.dashboard-col h4 {
    color: white;
    margin-bottom: 0%;
    margin-left: 5% !important;
    margin-top: 2%;
    float: left;
    width: 50%;
}

.dashboard-col p {
    margin-left: 5% !important;
    float: left;
    color: rgba(255, 255, 255, 0.5);
}

.dashboard-col:hover p {
    color: white !important;
    ;
}

.day {
    color: white !important;
    font-size: 20pt;
    float: left;
    width: 100%;
    margin-bottom: 0%;
}

.month {
    color: rgba(255, 255, 255, 0.5);
    font-size: 15pt;
    float: left;
}

.calendar-dashboard {
    margin-top: 1.5%;
    background-color: #313131;
    padding: 2%;
    border-radius: 5px;
    color: white;
}

.calendar-dashboard .rbc-time-view-resources .rbc-time-header-gutter,
.calendar-dashboard .rbc-timeslot-group,
.calendar-dashboard .rbc-row {
    background-color: #313131;
}

.calendar-dashboard .rbc-toolbar button {
    color: white;
}

.calendar-dashboard .rbc-active {
    color: black !important;
}

.calendar-dashboard .rbc-day-slot .rbc-time-slot {
    border-top: 1px solid #707070 !important;
}

.page-subtitle,
.page-title {
    color: white !important;
}

.item-icon-wrapper {
    width: 10% !important;
    margin-right: 7% !important;
    margin-top: -1%;
}

.nav-link span {
    margin-top: 1%;
}

.legenda {
    float: left;
    margin-right: 1%;
    padding: 1% 3%;
    background-color: #E06060;
    border-radius: 10px;
    color: white;
}

.legenda-icon {
    float: left;
    margin-right: 1%;
    padding: 0.5% 1%;
    color: white;
    width: 20%;
}

.legenda-icon img {
    width: 30px;
    margin-right: 4%;
}

.button-add-appointment {
    background-image: linear-gradient(to right, #006CFF, #9DCCE5);
    border-radius: 5px !important;
    padding-left: 8% !important;
    padding-right: 8% !important;
    font-weight: bold !important;
    float: right;
    margin-bottom: 5%;
    padding: 3% 15% !important;
    color: white;
}

.button-add-appointment:hover {
    opacity: 0.8;
}

.rbc-event-label {
    position: absolute;
    z-index: 1;
}

.custom-event {
    width: 105%;
    height: 105%;
    position: absolute;
    padding: 5%;
    padding-top: 19px;
    z-index: 0;
    padding-left: 8%;
    margin-left: -5%;
    float: left;
}

.rbc-event {
    padding: 0% !important;
}

.icon-calendar {
    float: left;
    width: 25px;
}

.rbc-event-label {
    display: none;
}

.main-details {
    width: 100% !important;

}

.main-details p {
    float: left;
    margin-right: 6%;
    width: 27%;
    font-style: bold !important;
}

.room-details {
    padding: 3%;
}

.room-details .amount-people img {
    width: 10%;
    margin-right: 5%;
}

.border-left {
    border-left-style: solid !important;
    border-left-width: 1px !important;
    border-left-color: black !important;
    padding-left: 6%;
}

.border-right {
    border-right-style: solid !important;
    border-right-width: 1 !important;
    border-right-color: black !important;
}

.room-details ul {
    width: 100%;
    float: left;
    padding-left: 0% !important;
    margin-top: 3%;
}

.room-details ul li {
    width: 33%;
    float: left;
    list-style: none;
    margin-bottom: 2%;
}

.room-details .detailed-info {
    padding: 3%;
    margin-top: 10%;
    background-color: #00AEEF;
    border-radius: 5px;
    color: white;
    float: left;
}

.textarea-rooms {
    width: 100% !important;
    min-height: 100px;
}

.room-details ul li i {
    color: #4DBE92;
    margin-right: 5% !important;
}

.room-details hr {
    width: 100%;
    float: left;
}

.room-pricing-details {
    padding-left: 2%;
    border-radius: 5px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    margin-top: 4.5%;

}

.room-pricing-details h3 {
    color: black;

    margin-top: 10%;
    margin-bottom: 4%;
}



.btn-primary {
    background-color: #27718F !important;
}

.room-pricing-details label {
    width: 100%;
    color: black !important;
    margin-bottom: 2%;
    margin-top: 5%;
    font-weight: bold !important;
}

.checkbox-extras {
    margin-bottom: 0%;
}

.MuiFormControlLabel-root {
    margin-top: 0% !important;
    margin-bottom: -2% !important;
}

.total-price-room-details {
    width: 100%;
    float: left;
}

.total-price-room-details p {
    width: 50%;
}

.total-price {
    width: 50% !important;
    float: left;
}

.calculated-price {
    width: 50% !important;
    float: left;
    text-align: right;
}

.bills-table {
    width: 100%;
}

.bills-table tr th,
.bills-table tr td {
    width: 20%;
}

.bills-table tr td {
    padding: 1%;
}

.bills-table {
    background-color: white !important;
    border-radius: 5px;
    padding: 5% !important;
}

.bills-table tr th {
    padding: 1% !important;
    ;
    border-bottom-color: grey;
    border-bottom-width: 1px;
    border-bottom-style: solid;
}

.table-link {
    float: right;
    margin-right: 5%;
}

.bills-component {
    margin-top: 5%;
    margin-bottom: 5%;
}

.bill-detail-list {
    width: 100%;
}

.bill-detail-list ul {
    padding-left: 0%;
}

.bill-detail-list ul li {
    list-style: none;
    float: left;
    width: 100%;
    color: white !important;
    font-weight: bold !important;
}

.bill-detail-list-element {
    float: right;
    font-weight: normal !important;
}

.back-link {
    padding: 1% 3%;
    border: white 1px solid;
    border-radius: 5px;
    margin-top: 14% !important;
    color: white;
}

.back-link:hover {
    background-color: white;
    color: black;
}

.black-space {

    padding: 10%;
    margin-bottom: 5%;
}

.error-message-room {
    padding: 3% 4% !important;
    margin-top: 4% !important;
    float: left;
    background-color: red;
    color: white;
    border-radius: 5px;

}



.home-banner {
    padding-top: 5% !important;
    padding-bottom: 5% !important;
    background: #27718f url('../assets/images/test-1.jpg') !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}

.about-us-image-page {
    background: #27718f url('https://firebasestorage.googleapis.com/v0/b/cashierr-pixelpros.appspot.com/o/images%2FFoto%20gezin-84-min.jpeg?alt=media&token=b9e14c83-e1cf-4e17-8685-1c8c435c4424') !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}

.about-us-banner {
    background-color: #27718f;
    padding-left: 0px !important;
}

.social-media-row {
    background-color: #27718f;
    padding: 4% 10%;
    margin-top: 5%;
}

.social-media-colum h2 {
    color: white;
    text-align: center;
    margin-bottom: 3%;
}

.social-media-colum {
    color: white;
    text-align: center;
}

.social-media-colum ul {
    width: 15%;
    margin-left: 42%;
}

.subtmit-button {
    width: 100%;
    padding: 2%;
    background-color: #27718f;
    border: none;
    border-radius: 5px;
    color: white;
    margin-top: 3%;
}

.social-media-colum ul li {
    list-style: none;
    float: left;
    margin-right: 10%;
    font-size: 25pt;

}

.social-media-colum ul li a {
    color: white;
    text-decoration: none;
}

.surroundings-row {
    background-color: #27718f;
    padding: 4% 10%;
    padding-bottom: 8%;
}

.detail-page {
    padding-top: 5%;
}

.contact-row {
    background-color: #27718f;
    padding: 2% 10% 2% 10%;
    margin-top: 2%;
}

.room-overview-row {
    background-color: #27718f;
    padding: 4% 10%;
    padding-bottom: 4%;
    margin-left: -3%;
}

.surroundings-colum h2,
.contact-colum h2 {
    color: white;
    text-align: center;
    margin-bottom: 0%;
}

.surroundings-colum,
.contact-colum {
    color: white;
    text-align: center;
}

.contact-colum a {
    color: white;
    font-weight: bold;
}

.settings-table tr th {
    color: white;
}

.small-button {
    padding: 1% 7% !important;
    margin-top: 4%;
}



.location-row {
    margin-top: 8%;
}

.contact-row-details {
    padding: 5% 0%;
    margin-bottom: 6%;
}

.location-colom iframe {
    width: 100%;
    height: 500px;
}



.light-text {
    color: white;
    text-align: center;
    width: 60%;
    margin: auto;
    margin-bottom: 4%;
}


.surprise-colom h2 {
    margin-top: 8%;
    margin-bottom: 5%;
}

.lunch-to-go h2 {
    margin-top: -8%;
}

.surprise-colom p {
    margin-bottom: 4%;
}

.surprise-colom ul li {}

.location-colom img {
    width: 100%;

}

.social-media-colum ul li a:hover {
    opacity: 0.8;
}

.home-page {
    width: 103% !important;
    margin-top: -4% !important;
}

.intro-text {
    padding: 10% 10% 0% 10%;
    color: white;
}

.intro-text h2 {
    text-align: center;
    margin-top: 5%;
}

.intro-text p {
    text-align: center;
    margin-bottom: 8%;
}

.search-col-small {
    padding-bottom: 2%;
}

.search-col-small h4 {
    font-size: 12pt;
    font-weight: 800;
    padding: 5% 1% 1% 1%;
}

.DayPickerInput input {
    width: 105% !important;
    padding: 2%;
    -webkit-appearance: none;
    border: solid 1.2px black !important;
}

.search-col-small select {
    width: 100%;
    padding: 2%;
    -webkit-appearance: none;
}

.between {
    border-left: solid rgba(0, 0, 0, 0.192) 1px;
    border-right: solid rgba(0, 0, 0, 0.192) 1px;
}

.button-search {
    padding-top: 3.5%;
    padding-bottom: 2%;
}

.search-button {
    margin-top: 5% !important;
    padding: 3% 30%;
    background-color: #2C718F;
    color: white;
    width: 90% !important;
    margin-left: 10%;
}



.search-button:hover {
    background-color: #1a4355;
    color: white;
    text-decoration: none;
}

.search-row {
    background-color: white;
}

.about-us-text-page {
    padding: 4%;
    padding-right: 10%;
    color: white;
    padding-top: 8%;
    padding-bottom: 8%;
}

.intro-text h2,
.about-us-text-page h2 {
    color: white;
}

.frontend-button-light {
    background-color: white;
    padding: 2% 3%;
    border-radius: 5px;
    color: black !important;
}

.about-us-intro {
    text-align: center;
    margin-top: 5%;
}

.frontend-button-light:hover,
.frontend-button-dark:hover {
    opacity: 0.8;
    text-decoration: none;
}

.frontend-button-dark {
    background-color: #27718f;
    padding: 2% 3%;
    border-radius: 5px;
    color: white !important;
}

.about-us-text {
    padding: 4%;

}

.about-us-text h2 {
    margin-top: 4%;
    margin-bottom: 5%;
}

.about-us-image img {
    width: 90%;

    margin-top: 12%;
    margin-bottom: 12%;
}

.facilities-home h2,
.about-us-home h2,
.surroundings-home h2 {
    text-align: center;
    margin-top: 8%;
    color: black;
}

.about-us-home p {
    text-align: center;
    font-size: 12pt;
}

.facilities-home h4,
.about-us-home h4 {
    margin-top: 2%;
    text-align: center;
    color: #333333;
}

.facilities-home h5 {
    text-align: center;
}

.surroundings-home h5 {
    text-align: center;
}

.custom {
    float: left;
    font-size: 30px;
    color: #27718f;
}

.facilities-home h5 {
    float: left;
    margin-left: 5%;
}

.facilities-home p {
    float: left;
    margin-left: 13%;
}

.padding-six-left {
    padding-left: 10%;
    margin-top: 3%;
}

.padding-six-right {
    padding-right: 10%;
    margin-top: 3%;
}

.about-us-home-pictures img {
    width: 70%;

    margin-top: 3%;
}

.left img {
    margin-left: 30%;
}

.right img {
    margin-right: 30%;
}

.right h3 {
    width: 40%;
    margin-left: 20%;

}

.right h5 {
    width: 40%;
    margin-left: 15%;
}

.left h3 {
    width: 40%;
    margin-left: 50%;

}

.left h5 {
    width: 40%;
    margin-left: 43%;
}

.about-us-home-pictures h3 {


    margin-top: 3%;
    color: black;
    margin-bottom: 2%;
}

.about-us-home-pictures h5 {
    color: black;
    text-align: center;
}

.about-us-home-row {
    margin-bottom: 8%;
}

.contact-colom-details ul {
    padding-left: 0%;
}

.contact-colom-details h2 {
    margin-bottom: 5%;
    margin-top: 10%;
}

.contact-colom-details ul li {
    list-style: none;
    margin-bottom: 3%;
}

.contact-colom-details-form h2 {
    margin-bottom: 5%;
    margin-top: 7%;
}

.map-integration iframe {
    width: 100%;
    border: none;
    margin-left: -1%;
    height: 400px;
    margin-bottom: -1%;
    padding-bottom: 0%;
}


.contact-colom-details-form input {
    width: 48%;
    padding: 1% 2%;
    margin-right: 2%;
    margin-bottom: 1%;
}

.contact-colom-details-form textarea {
    width: 98%;
    margin-top: 3%;
    padding: 1% 2%;
    min-height: 100px;
    margin-bottom: 4%;
}

.detail-row {
    padding-left: 5%;
    padding-right: 5%;
    margin-bottom: 10%;
}

.thank-you-page-colum iframe {
    width: 100%;
    border: none;
    min-height: 200px;
}

.backend-form label {
    color: white;
}

.backend-form input,
.backend-form select,
.backend-form textarea {
    width: 80%;
    padding: 1%;
    -webkit-appearance: none;

}

.backend-form .btn {
    margin-right: 2%;
}

.backend-form .button-create-appointment {
    margin-top: 5%;
}


.backend-form .back-link {
    padding: 1% 3%;
    border: white 1px solid;
    border-radius: 5px;
    margin-top: 5% !important;
    color: white;
    float: left;
    margin-right: 6%;
}

.backend label,
.backend h5,
.total-cash,
.list-title {
    color: white
}

.mobile,
.mobile-menu {
    display: none;
}

.image-settings img {
    width: 100%;
    margin-bottom: 6%;
}

.images-settings-row {
    border: white 2px solid;
    padding: 2% 2% 1% 2%;
    border-radius: 5px;
    margin-bottom: 3%;
}

.add-button-settings {
    float: right;
    margin-bottom: 2%;
}

.image-settings span {
    position: absolute !important;
    margin-top: 5%;
    z-index: 999;
}

.image-settings input {
    width: 20%;
    margin-left: 10%;
}

.image-settings label {
    width: 30%;
    margin-top: -6%;
    margin-left: 10%;
}

.image-settings input,
.image-settings label {
    float: left;
}

.delete-image {
    margin-left: 55%;
    margin-top: 2% !important;
}



.add-facilitie-table {
    width: 100%;
    background-color: white;
    border-radius: 5px;
}

.add-facilitie-table tr th,
.add-facilitie-table tr td {
    padding: 2%;
    border-bottom: solid grey 1px;
}

.MuiInput-root {
    width: 100% !important;
}

.total-breakfast {
    width: 100%;
    background-color: #304B7B !important;
    color: white;
    padding: 4%;
    border-radius: 5px;
}

.total-breakfast li {
    list-style: none;

}

.total-breakfast hr {
    border-color: white;
}

.total-breakfast span {
    text-align: right;
    float: right;
}

/*.frontend-form button{
        
        margin-bottom: 10%;
    }*/
.frontend-form {
    margin-top: 5%;
}

.newsletter-adding {
    position: absolute;
    bottom: 6em;
    margin-left: 17.5%;

}

.newsletter-adding input[type="checkbox"] {

    margin-top: 5%;
}


.frontend-form input,
.frontend-form select {
    width: 100%;
    padding: 1% 3%;
    -webkit-appearance: none;
}

.backend-form .error-message-room {
    width: 100%;
}

.react-calendar__month-view__days__day--weekend {
    color: black !important;
}



.frontend-table table {
    width: 100%;
    margin-top: 10%;
    margin-bottom: 10%;
    border: solid 1px black;
    border-radius: 10px;
    ;
}

.frontend-table h2 {
    margin-top: 5%;
    color: black;
}



.frontend-table table tr td {
    width: 50%;
    padding: 2%;
    border: solid 1px grey;
}

.total-oder {
    font-weight: 900;
}

.total-fat {
    font-weight: 700;
}

.frontend-table button {
    margin-bottom: 10%;
    width: 20%;
}

.switch button {
    margin-right: 2%;
}

.room-overview-home {
    background-color: white;
    padding: 2%;
    margin-right: 3%;
    width: 30%;
    float: left;
    margin-bottom: 10%;

}

.room-overview-home img {
    width: 100%;

}

.amount-people img {
    width: 5%;
}

.room-overview-home ul {
    list-style: none;
    padding-left: 0%;
}

.room-overview-home ul li {
    text-decoration: none;
}

.room-overview-home ul li i {
    color: #4DBE92;
}

.room-overview-home h2 {
    color: black !important;
    text-align: left;
}

.home-from {
    margin-top: 0% !important;
    margin-bottom: 0%;
}

.room-overview-home h3 {
    margin-top: 0% !important;
    margin-bottom: 0% !important;
}

.last {
    margin-bottom: 5% !important;
}

.spinner-col {
    margin-left: 48.5%;
    padding-top: 5%;
    padding-bottom: 3%;
}

.spinner-col-title h3 {
    text-align: center;
    margin-bottom: 20%;

}

.login-col {
    padding: 15% 10%;
    background-color: white;
}

.login-col img {
    width: 60%;
}

.search-text {
    text-align: center;
}

.login-col h3 {
    margin-top: 8%;
    color: black
}

.login-col p {
    margin-bottom: 3%;
}

.login-col input {
    width: 90%;
    margin-left: 3%;
    border-radius: 50px;
    border: 1px grey solid;
}

.login-col button {
    width: 50%;
    background-color: #2C718F;
    padding: 2%;
    border-radius: 50px;
    margin-top: 4%;
    border: none;
    color: white;
}

.login-col button:hover {
    background-color: #21576e;
}

.login-image-bg {
    background-image: url("../../src/assets/images/test-1.jpg");
    background-color: #cccccc;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.not-found h2 {
    text-align: center !important;
}

.not-found a {
    margin: auto;
    background-color: #2C718F;
    padding: 1% 3%;
    color: white;
    margin-top: 3%;
}

.footer-sitemap li a {
    color: white;
}

.extra-margin-bottom {
    margin-bottom: 10%;
}

.DayPicker-Months {
    background-color: white;

}

.room-pricing-details select {
    width: 100%;
    padding: 2%;
}

.thumbs {
    padding-left: 0px;
}

.choice-family label {
    font-weight: 500 !important;
    float: left !important;
    width: 90% !important;
    margin-top: 0%;
    margin-bottom: 0%;
}

.choice-family {
    width: 100% !important;
    display: inline-block;
}

.choice-family input {
    font-weight: 500 !important;
    float: left !important;
    width: 10% !important;
    margin-top: 2%;
}

.error-home {
    color: red;
}

.breakfast .DayPickerInput {
    width: 96% !important;
}

.warning-button {
    background-color: red !important;
}

.change-app-button {

    float: left;
    width: 70%;
}

.overlay-row {
    position: absolute;
    background-color: black;
    z-index: 99999;
    width: 123%;
    margin-left: -23%;

    margin-top: -8%;
    padding-top: 8%;
    padding-bottom: 8%;
}

.overlay-col {
    background-color: white;

    padding: 2%;
    border-radius: 20px;
    margin-left: -5% !important;
}

.overlay-col label {
    color: black;
}

.calendar-change-width {
    width: 50%;
    float: left;
}

.change-ul-li {
    padding-left: 0%;
}

.overlay-col hr {
    width: 80%;
    float: left;
    margin-right: 20%;
    margin-top: 5%;

}

.change-ul-li li {
    list-style: none;
}



.shop-product-detail h2 {
    color: black
}

.react-calendar__tile {
    background-color: #71EAA8;
}

.react-calendar__tile:disabled {
    background-color: #ff6666 !important;
}

.overlay-top {
    margin-top: -12%;
    margin-bottom: 10% !important;
}

.overlay-top h2 {
    margin-top: 30%;
}

.overlay-top img {
    margin-left: -5%;
}

.search-button-fix {
    margin-top: 10% !important;
    padding: 2%;
    background-color: #2C718F;
    color: white;
    width: 25% !important;
    margin-left: 43%;

}

.search-button-fix:hover {
    opacity: 0.8;
    color: white;
    text-decoration: none;
}

.qr-container {
    display: flex;
    justify-content: space-between;
}

.box {

    text-align: center;
}

.box h4 {
    font-size: 12pt;
}

.box img {
    width: 200px;
}

.room-prices {
    display: flex;
    /* Flex container voor prijsdivs */
    flex-wrap: wrap;
    /* Zorgt dat items naar de volgende regel kunnen springen bij beperkte ruimte */
    align-items: left;
    /* Centreert items verticaal */
    justify-content: space-around;
    /* Verdeelt ruimte tussen en rond items */
}


.price-item {
    width: 100px;
}

.price-item h3 {

    font-size: 18pt;
    /* Ruimte rond elke prijsdiv */

}

.disabled-button {
    opacity: 0.4;
}

.DayPicker-Day--disabled {
    opacity: 0.3;
}