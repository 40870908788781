

.room-row{
    border-radius: 5px;
    margin-bottom: 4%;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    
}

.room-overview{
  padding: 5%;
}

.half-input{
  width: 44.5%!important;
  float: left!important;
}

.half-input input{
padding: 2% 3%!important;
}

.right-input-half{
  margin-right: 2%;
}

.right-input-half-mobile {
  margin-right: 0%;
  margin-left: 11%;
}


.room-image img{
    width: 100%;
    height: 100%;
    margin-left: -4%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.room-information{
    padding: 2%;
}

.MuiButtonGroup-groupedHorizontal:hover{
  opacity: 0.8;
}

.MuiButtonGroup-groupedHorizontal:active{
  background-color: black!important;
}
.room-information tr:not(:first-child),.room-details tr:not(:first-child){
    box-shadow: none!important;
}

.room-information table ,.room-details table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  .room-information ul{
    margin-top: -3%;
    margin-bottom: 4%;
    padding-left: 0%;
  }

  .room-information ul li{
    width: 33%;
    float: left;
    list-style: none;
  }

  .room-information ul li i{
    color:green;
  }
  .room-information td i{
    color: green;
    margin-right: 5%;
  }
  /*
  .room-information td,  .room-information th,.room-details td, .room-details th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  
  .room-information tr:nth-child(even), .room-details tr:nth-child(even) {
    background-color: #dddddd;
  }*/

  .room-information p{
      margin-top: 2%;
 width: 100%;
  }

  .room-information-desc{
 width: 100%;
    float: left;
    margin-top: 4%!important;
  }

  .room-price p{
    text-align: center;
    margin-top: 15%;
  }

  .room-price h3{
      text-align: center;
      margin-top: -6%!important;
    margin-bottom: -15%!important;

  
  }
  .room-button{
    margin-left: 10%;
      background-color: #334B5F!important;
      border-radius: 5px!important;
        width: 80%;
      padding: 3%;
      text-align: center;
      font-weight: bold!important;
      border-color: #334B5F!important;
      color: white;
      font-size: 9pt;
      float: left;
      margin-bottom: 5%;
      margin-top: 5%;
  }

  .room-button:hover,.room-price-button:hover{
background-color: #92c0e6!important;
color: black;
text-decoration: none;
  }

  .room-price-button{
    float: right!important;
    background-color: #334B5F!important;
    border-radius: 5px!important;
      width: 100%;
    padding: 3%;
    text-align: center;
    font-weight: bold!important;
    border-color: #334B5F!important;
    color: white;
    font-size: 9pt;
    float: left;
    margin-bottom: 5%;
    margin-top: 5%;
  }

  .no-dates-selected{
    float: right!important;

    border-radius: 5px!important;
      width: 100%;
    padding: 3%;
    text-align: center;
    font-weight: bold!important;
    border-color: white!important;
    border-style: solid;
    border-width: 1px;
    color: white;
    font-size: 9pt;
    float: left;
    margin-bottom: 5%;
    margin-top: 5%;
    opacity: 0.6;
  }

  .no-dates-selected p{
    margin-bottom: 0%;
  }

.room-details img{
width:100%;
}

.room-details table{
    margin-top:4%;
}

.detailed-info{
    padding:3%;
    margin-top:4%;
    background-color: #00AEEF;
    border-radius:5px;
    color:white;

}

.MuiTypography-body1{
  margin-right: 10%!important;
  font-size: 0.875rem!important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif!important;
    font-weight: 400!important;
    line-height: 1.43!important;
    letter-spacing: 0.01071em!important;
}


.border-right{
  border-right-color: black;
  border-right-style: solid;
}

.room-information h2,.room-details h2{
  color:black;
  font-size: 22pt;
}

.room-details h3{
  color: black;
  margin-top: 10%;
}



.nav{
  width: 94%!important;
}

.active, .nav-link:hover{
  background-image: linear-gradient(to right, #006CFF, #9DCCE5);
}

.nav-item{
   
   margin:3%;
  border-radius: 5px;
  width: 20%!important;
  }

  .highlighted-picture img{
    width: 100%;
    max-height: 362px;
    border-radius: 5px;
    object-fit: contain;
  }


  .collected-pictures img{
    width: 270px;
    height: 180px;
    float: left;
    border:white 2px solid;
    border-radius:5px ;
    object-fit:cover;
  }
  .collected-pictures img:hover{
    opacity: 0.8;
    cursor: pointer;
  }

  .footer{
    width: 105%;
    margin-left: -2%;
    margin-top: 10%;
    padding:1%;
    background-color: #27718F;
  }

  .footer p{
    text-align: center;
    color: white;
    margin:0%;
  }

  .carousel{
    margin-top: 2%;
    box-shadow: none!important;
  }

  .carousel-root{
    margin-top: 5%;
  }

  .succes-send-form{
    position: absolute;
    right: 0;
    background-color: #27718F;
    padding:2% 3% 0% 3%;
    margin-right: 2%;
    border-radius: 22px;
    margin-top: -8%;
    z-index: 999;
    color:white
}

.succes-send-form h5{
    color: white;
}

.loading-dots{
    background-color: #28654B;
    width: 30%;
    border-radius: 22px;
    padding-left: 10%;
    position: absolute;
    height: 30px;
    margin-top: 15%!important;
top: 100;}
