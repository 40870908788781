@media only screen and (max-width: 500px) {
    .search-button-fix {
        margin-top: 10% !important;
        padding: 5%;
        background-color: #2C718F;
        color: white;
        width: 5% !important;
        margin-left: 25%;
        margin-bottom: 15%;

    }

    .search-button-fix:hover {
        opacity: 0.8;
        color: white;
        text-decoration: none;
    }

    .intro-text p {
        margin-bottom: 20%;
    }

    .intro-text {
        padding-bottom: 5%;
    }

    .mobile {
        display: block;
    }

    .desktop-menu {
        display: none;
    }

    .mobile-menu {
        display: block;
    }

    .home-page {
        width: 109% !important;
        margin-top: -4% !important;
    }

    .sub-page .amount-people img {
        width: 17% !important;
        margin-right: 9% !important;
        margin-left: 1%;
        margin-top: -3.5%;
        display: none;
    }

    .room-information ul li {
        width: 100%;
        float: left;
        list-style: none;
        margin-bottom: 2%;
    }

    .location-colom {
        padding: 10%;
    }

    .surprise-colom {
        padding: 10%;
    }

    .room-information {
        padding: 6%;
    }

    .surprise-colom h2 {
        margin-top: -8%;
        margin-bottom: 5%;
    }

    .surprise-colom .frontend-button-dark {
        background-color: #27718f;
        padding: 3% 36%;
        border-radius: 5px;
        color: white !important;
        width: 100% !important;
        margin-top: 10% !important;
    }

    .border-left {
        padding-left: 0% !important;
        border: none !important;
    }

    .room-details ul li {
        width: 100%;
        float: left;
        list-style: none;
        margin-bottom: 2%;
    }

    .main-details p {
        float: left;
        margin-right: 6%;
        width: 100%;
        margin-bottom: 5%;
        font-style: bold !important;
    }


    .about-us-image img {
        width: 100%;
        margin-left: 0;
        margin-top: 14%;
        margin-bottom: -11%;
    }

    .frontend-form button {
        width: 100%;
        padding: 3%;
        background-color: #2C718F;
        border-radius: 5px;
        border: none;
        color: white;
    }




    .facilities-intro {
        padding-left: 0px !important;
    }

    .padding-six-left {
        padding-left: 2%;
        margin-top: 3%;
        margin-right: 12%;
    }

    .padding-six-left p {
        padding-left: 2.5% !important;
    }

    .facilities-home p {
        float: left;
        margin-left: 13.5%;
    }

    .facilities-home h5 {
        font-size: 13pt;
    }

    .left img {
        margin-left: 6%;
    }

    .about-us-home-pictures img {
        width: 80%;
        margin-top: 3%;
    }

    .about-us-home {
        margin-left: -3%;
    }

    .left h3 {
        width: 50%;
        margin-left: 20%;
    }

    .left h5 {
        width: 40%;
        margin-left: 24%;
    }

    .right img {
        margin-right: 0%;
        margin-left: 6%;
    }

    .right h3 {
        width: 74%;
        margin-left: 18%;
    }

    .right h5 {
        width: 74%;
        margin-left: 9%;
    }

    .footer-section {
        background-color: #27718F;
        width: 114%;
        margin-left: -7%;
        padding: 5% 5%;
        padding-left: 5%;
        padding-bottom: 0%;
        padding-top: 20%;
        margin-top: 10%;
    }

    .footer-section h3 {
        margin-top: 5%;
    }

    .key-aelse img {
        width: 50%;
        margin-left: 25%;
        margin-bottom: -15%;
    }

    .social-media-colum {
        margin-left: -7% !important;
    }

    .social-media-colum ul {
        width: 80%;
        margin-left: 6%;
    }

    .social-media-colum ul li {
        list-style: none;
        float: left;
        margin-right: 13%;
        font-size: 25pt;
        width: 20%;
    }

    .social-media-row {
        background-color: #27718f;
        padding: 12% 10%;
        margin-top: 5%;
    }

    .location-row {
        margin-left: -12%;
    }

    .surroundings-row {
        margin-left: -13%;
    }

    .location-colom h5 {
        margin-top: 5%;
    }


    .location-colom img {
        margin-top: -15%;
    }

    .desktop {
        display: none;
    }

    .contact-row {
        margin-left: -13%;
    }

    .contact-row-details {
        margin-left: -8%;
    }

    .map-integration iframe {
        width: 100%;
        border: none;
        margin-left: -4%;
        height: 400px;
        margin-bottom: -1%;
        padding-bottom: 0%;
    }

    .room-overview-row {
        background-color: #27718f;
        padding: 4% 10%;
        padding-bottom: 4%;
        margin-left: -12%;
    }

    .room-row {
        border-radius: 5px;
        width: 98%;
        margin-bottom: 18%;
        box-shadow: 0 14px 28px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 22%);
    }

    .room-image img {
        width: 110%;
        height: 100%;
        margin-left: -5%;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 5px;
    }

    .room-details p {
        float: left;
    }


    .ham {
        position: absolute;
        z-index: 1000;
        top: 20px;
        right: 26px;
        width: 44px;
        height: 36px;
        border: none;
        cursor: pointer;
        background-color: transparent;
        background-image: url(https://ljc-dev.github.io/testing0/ham.svg);
        background-size: 100%;
    }

    .mobile-menu {
        background-color: #27718F;
        width: 111%;

    }

    .mobile-menu img {
        width: 43%;
        margin-top: 9%;
        margin-bottom: 11%;
        margin-left: 2%;
    }

    .frontend-form form {
        width: 92%;
    }

    .total-breakfast {
        width: 93%;
        background-color: #304B7B !important;
        color: white;
        padding: 4%;
        border-radius: 5px;
    }

    .frontend-form {
        padding-bottom: 0%;
    }

    .frontend-form button {
        width: 93%;
        padding: 3%;
        background-color: #2C718F;
        border-radius: 5px;
        border: none;
        color: white;
        margin-left: 0%;
    }

    /* change ham image to close */
    .showClose {
        background-image: url("https://ljc-dev.github.io/testing0/ham-close.svg");
    }

    .navbar-mobile {
        position: absolute;
        top: 0;
        left: 0;
        background: #27718F;
        width: 111%;
        height: 120vh;
        overflow: hidden;
        color: white;
        z-index: 800;
        transform: translateY(-130%);
        transition: transform 0.2s ease;

        margin-left: -2%;
        margin-right: -34% !important;

    }

    .highlighted-picture img {
        margin-top: 5%;
    }


    .showNav {
        /* show the menu */
        transform: translateY(-10%);
    }

    /* basic nav styling below */

    .navbar-mobile ul {
        width: 100%;
        height: 100%;
        list-style: none;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-evenly;
        align-items: center;
        margin-left: -5%;
    }

    .navbar-mobile ul li a {
        color: white;
        padding-bottom: 10px;
        border-bottom: 2px solid white;
        text-decoration: none;
        font-family: 'Open Sans', sans-serif;
        font-size: 1.2rem;
    }

    .navbar-mobile ul li {
        width: 50%;
        margin-bottom: -48%;
        margin-top: -60%;
        margin-left: 0%;
        text-align: center;
    }

    .navbar-mobile ul li a:hover,
    .navbar-mobile ul li a:focus {
        border-bottom: 2px solid white;
    }

    .checkout-layout {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 10%;
    }

    .button-search {
        margin-bottom: 4% !important;
        margin-top: 5%;
        width: 100%;
    }

    .search-col-small input {
        width: 100%;
        padding: 1% 2%;
    }

    .search-col-small select {
        width: 100%;
        padding: 2%;
    }

    .search-button {
        margin-top: 5% !important;
        padding: 3% 42%;
        background-color: #2C718F;
        color: white;
        width: 94% !important;
        margin-left: 1% !important;
    }


    .room-overview-home {
        background-color: white;
        padding: 3%;
        margin-right: 7.5%;
        margin-left: 7.5%;
        width: 85%;
        float: left;
        margin-bottom: 10%;
    }

    .rooms-home {
        margin-left: -12% !important;
        margin-top: 20%;
    }

    .min-margin {
        margin-left: 0px !important;
    }

    .collected-pictures img {
        width: 270px;
        height: 180px;
        float: left;
        border: white 2px solid;
        border-radius: 5px;
        width: 100%;
    }

    .spinner-col {
        margin-left: 42%;
        padding-top: 5%;
        padding-bottom: 3%;
    }

    .DayPickerInput input {
        width: 192% !important;
        padding: 7% !important;
        -webkit-appearance: none;
        border: solid 1.2px black !important;
    }

    .mobile-not-found {

        padding: 9% !important;
        margin-right: 13% !important;

    }

    .not-found a {
        margin: auto;
        background-color: #2C718F;
        padding: 2% 21% !important;
        color: white;
        margin-top: 3%;
    }

    .about-us-image-page {
        background: #27718f url('https://firebasestorage.googleapis.com/v0/b/cashierr-pixelpros.appspot.com/o/images%2FFoto%20gezin-84-min.jpeg?alt=media&token=b9e14c83-e1cf-4e17-8685-1c8c435c4424') !important;
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        padding: 55%;
    }

    .newsletter-adding {
        position: absolute;
        bottom: 16em;
        margin-left: 0.5%;
    }

    .payment-mobile {
        margin-top: 15%;
    }


    .overlay-top {
        flex-direction: column-reverse;
    }

    .overlay-top h2 {
        margin-top: -0%;
        margin-left: 6%;
    }

    .overlay-top p {
        margin-left: 6%;
    }

    .overlay-top a {
        margin-left: 6%;
        margin-bottom: 35%;

    }
}