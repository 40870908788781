@media only screen and (max-width: 1440px) {
/* page about the settings of the application */
.button-settings{
    background-color: white;
    padding: 2% 2%;
    width: 100%;
    border-color:#f0f0f0;
    font-weight: 700;
    border-style: solid;
    border-width: 1px;
    text-align: center;
    -webkit-box-shadow: 4px 4px 16px -6px rgba(0,0,0,0.75);
-moz-box-shadow: 4px 4px 16px -6px rgba(0,0,0,0.75);
box-shadow: 4px 4px 16px -6px rgba(0,0,0,0.75);
    
}

.button-settings:hover, .button-settings:hover a{
    background-color: #5EBDE9;
    color:white;
}

.button-settings a{
    color: #334B5F;
    text-decoration: none;
}

.small-settings-section{
    background-color: white;
    border-radius: 5px;
    padding-top: 2%;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    border-style: solid;
    border-width: 1px;
    border-color: #70707038;
    width: 100%;
}

.small-settings-section li{
    list-style: none;
    padding: 2% 4% 3% 4%;
    border-bottom-style: solid;
    border-bottom-color:  #70707038;
    border-bottom-width: 1px;
}
}