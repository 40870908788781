h1, h2, h3{
  font-family: 'Roboto', sans-serif!important;
  font-weight: bold!important;
}

h4, h5, h6{
  font-family: 'Roboto', sans-serif!important;
  font-weight: 500!important;
}

p{
  font-family: 'Roboto', sans-serif!important;
}

.main-content-container, body,.main-content,.container-fluid, #root{
  background-color: #ffffff!important;
}

.add-new-post__editor .ql-container,
.add-new-post__editor .ql-editor {
  min-height: 400px;
}

.nav-ffort .nav-item .nav-link{
  color: white!important;
}
.nav-ffort .nav-item .nav-link .material-icons{
  color: white!important;
}

.nav-wrapper{
  background-color: #334B5F!important;
}

.min-twee{
  background-image: url('/assets/images/background-dummy.jpg');
}

.settingsBlocks{
  background-color: #ED6A1C;
  padding: 10% 5% 10% 5%;
  border-radius: 20px;
  margin-bottom: 10%;
}
.active-block{
  opacity: 0.5;
  background-color: #ED6A1C;
  padding: 10% 5% 10% 5%;
  border-radius: 20px;
}

.active-block a{
  color: white!important;
  display: flex;
  justify-content: center;

}

.settingsBlocks a{
  color: white!important;
  text-align: center;
  display: flex;
  justify-content: center;

}

.settingsBlocks i{
  color: white;
  font-size: 24pt;
  display: flex;
  justify-content: center;
}

.settingsBlocksCat i{
  color: white;
  font-size: 24pt;
  display: flex;
  justify-content: center;
}

.settingsBlocksCat{
  background-color: #ED6A1C;
  padding: 20% 5%;
  border-radius: 20px;
  width: 90%;
  height: 90%;
  overflow-wrap: break-word;
}

.logout-button{
  position: absolute;
    bottom: 0;
    left: 0;
  font-weight: bold;
  padding: .9375rem 1.5625rem;
  background-color: #5EBDE9;
  width: 100%;
  color: white;
}

.logout-button:hover{
  background-color: rgb(70, 145, 180);
}
.titles-row{
  margin-bottom: 2%;
}

.text-middle{
  text-align: center;
  margin-top: -25%;
  margin-bottom: 24%;
}

.title-middle{
  text-align: center;
}



/* animation */
@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}




.image-steps{
  width: 40%;
  margin-left: 30%;
  margin-top: 5%;
}

.logo-ffort{
  width: 30%;
  margin-top: 10%;
  margin-bottom: 5%;  
  margin-left: 35%;

}

.image-steps-title{
  text-align: center;
  margin-top: 13%;
  margin-bottom: 13%;
  color: white;
  font-weight: bold;

}

.input-field-sets{
  width: 100%;
  border-bottom-style: solid;
  border-left: none;
  border-right: none;
  border-top: none;
  padding: 2%;
  border-bottom-color: grey;
  border-bottom-width: 1px;
}

.image-steps-select{
color: white;
}

.bord-settings{
margin-top: 2%;
}

.titles-setup{
  font-weight: bold;
  color:white;

}

.button-setup{
  background-color: #ED6A1C!important;
    border-radius: 50px!important;
    padding: 2% 8%!important;

    font-weight: bold!important;
    border-color: #ED6A1C!important;
    
}

.button-reset{
  background-color: #ED6A1C!important;
    border-radius: 50px!important;
    padding: 2% 8%!important;

    font-weight: bold!important;
    border-color: #ED6A1C!important;
    margin-bottom: 5%;
}

.participants{
  margin-top: 5%;
  margin-bottom: -5%!important;
}

.button-cat{
  background-color: #ED6A1C!important;
  border-radius: 50px!important;
  font-weight: bold!important;
  border-color: #ED6A1C!important;
  width: 100%;
  margin-top: 15%;
  padding: 4% 8%!important;
}

.button-add{
  background-color: #ED6A1C!important;
    border-radius: 50px!important;
    padding-left: 8%!important;
    padding-right: 8%!important;
    font-weight: bold!important;
    border-color: #ED6A1C!important;
    width: 100%;
    margin-top: 15%;
}



.button-chashdesk{
  background-color: #334B5F!important;
  border-radius: 50px!important;
  padding-left: 8%!important;
  padding-right: 8%!important;
  font-weight: bold!important;
  border-color: #334B5F!important;
  float: left;
  margin-bottom: 5%;
  margin-top: 3%;
}

.link-chashdesk{
  background-color: #334B5F!important;
  border-radius: 50px!important;
    width: 20%;
  padding: 1%;
  text-align: center;
  font-weight: bold!important;
  border-color: #334B5F!important;
  color: white;
  font-size: 9pt;
  float: left;
  margin-bottom: 5%;
  margin-top: 3%;
}

.button-create-appointment{
  background-color: #334B5F!important;
  border-radius: 50px!important;
  padding-left: 8%!important;
  padding-right: 8%!important;
  font-weight: bold!important;
  border-color: #334B5F!important;
  float: left;
  margin-bottom: 5%;
}

.time-table input{
  text-align: center;
  width: 50%;
}

.time{
  margin-right: -2%;
  width: 50%;
}

.time-left{
  margin-left: -4%;
  width: 50%!important;
}

.post-it-update{
  float: right;
  margin-top: 1%;
  color: green;
}

.post-it-close{
  float: left;
  margin-top: 1%;
  margin-left: -35%;
  text-decoration: underline;
}
.overlay{
  position: fixed!important;
  width: 100%!important;
  height: 100%!important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 999;
  cursor: pointer;
  padding: 5% 0% 20% 20%!important;
}

.add-appointment-block{
  background-color: white;
  border-radius: 5px;
  padding: 5% 5%!important;
  margin-top: -8%!important;
  margin-left: -3%;
  width: 100%;
  -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.5s; /* Firefox < 16 */
   -ms-animation: fadein 0.5s; /* Internet Explorer */
    -o-animation: fadein 0.5s; /* Opera < 12.1 */
       animation: fadein 0.5s;
}

.add-appointment-block label{
  font-weight: bold;
  font-size: 14pt;
}

.add-appointment-block input,.add-appointment-block select, .settings-chaskdesk select{
  width: 100%;
  padding: 2%;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
}

.marginTop select{
  width: 100%;
  padding: 1%;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
}

.button-add-category{
  background-color: #ED6A1C!important;
    border-radius: 50px!important;
    padding-left: 8%!important;
    padding-right: 8%!important;
    font-weight: bold!important;
    border-color: #ED6A1C!important;
    width: 30%;
    margin-top: 3%;
}

.link-setup{
  background-color: #ED6A1C!important;
  border-radius: 50px!important;
  padding-left: 8%!important;
  padding-right: 8%!important;
  padding: 1%;
  font-weight: 700!important;
  border-color: #ED6A1C!important;
  color: white!important;
  font-size: 9pt;
}

.link-setup-2{
  background-color: #ED6A1C!important;
  border-radius: 50px!important;
  padding-left: 8%!important;
  padding-right: 8%!important;
  padding: 2%;
  font-weight: 700!important;
  border-color: #ED6A1C!important;
  color: white!important;
  font-size: 9pt;
}

.link-setup-afronden{
  background-color: #ED6A1C!important;
  border-radius: 50px!important;
  padding-left: 8%!important;
  padding-right: 8%!important;
  padding: 2.5%;
  font-weight: 700!important;
  border-color: #ED6A1C!important;
  color: white!important;
  font-size: 9pt;
  width: 75%;
  text-align:  center;
}

.link-setup-categorie{
  background-color: #ED6A1C!important;
  border-radius: 50px!important;
  padding-left: 8%!important;
  padding-right: 8%!important;
  padding: 1%;
  font-weight: 700!important;
  border-color: #ED6A1C!important;
  color: white!important;
  font-size: 9pt;
  width: 75%;
  text-align:  center;
  margin-top: 5%!important;
}

.button-adding{
  margin-top: 2%;
}

.button-sorting{
  background-color: #ED6A1C!important;
  border-radius: 50px!important;
  padding-left: 10%!important;
  padding-right: 10%!important;
  font-weight: bold!important;
  padding-top: 3%!important;
  padding-bottom: 3%!important;
  width: 100%;
 
}

.bord-settings input{
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: solid;
  border-bottom-width: 1px;
  padding: 2%;
  width: 50%;
}

.bord-settings li{
  list-style: none;
  border-bottom-style: solid;
  width: 80%;
  padding-bottom: 2%;
  border-bottom-width: 1px;
  margin-bottom: 2%;
}
.archive-list{
  margin-bottom: 5%;
}

.error-message{
  background-color: rgba(255, 0, 0, 0.8);
  padding: 3%;
  padding-bottom: 0.2%;
  border-radius: 20px;
  color: white;
  margin-top: 3%;
}

.archive-list h6{
  padding-bottom: 1%;
  margin-top: 5%;

}

.archive-list li{
  list-style: none;
  border-bottom-style: solid;
  width: 100%;
  padding-bottom: 1%;
  border-bottom-width: 1px;
  margin-top: 1%;
}

.bottom-col{
  margin-bottom: 10%;
}

.modal-background{
  height: 100vh;
  background-size: cover;
}

.start-block{
  background-color: white;
  border-style: solid;
  border-width: 1px;
  border-radius: 20px;
  margin-top: 10%;
  padding-bottom: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-block-left{
  background-color: white;
  border-left-style: solid;
  border-top-style: solid;
  border-bottom-style: solid;
  border-right-style: none;
  border-width: 1px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  margin-top: 10%;
  padding-bottom: 3%;
  padding-top: 2.5%;
}

.login-block-left h3{
  text-align: center;
  font-weight: bolder;
  font-family: 'Montserrat' ;
}

.login-block-left h4{
  text-align: center;
  font-family: 'Montserrat' ;
}

.login-block-left p{
  text-align: center;
  font-family: 'Montserrat' ;
  font-weight: 300;
}

.login-block-left input{
  width: 60%;
  margin-left: 20%;
  padding:1%;
  border-radius: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: #707070;
  padding-left: 3%;
}

.login-block-left button{
  margin-left: 19%;
  width: 62%;
  padding: 2%;
}

.login-block-right{
  background-color: white;
  background-image: url('./images/background-shop.jpg');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center; /* Center the image */
  border-right-style: solid;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: none;
  border-width: 1px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  margin-top: 10%;
  padding-bottom: 5%;
  display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
}

.question-block{
  background-color: white;
  border-style: solid;
  border-width: 1px;
  border-radius: 20px;
  margin-top: 5%;
  padding-bottom: 3%;
 
}

.sorting-row{
  padding-left: 14%;
}
.sorting-block{
  background-color: white;
  border-style: solid;
  border-width: 1px;
  border-radius: 20px;
  margin-top: 5%;
  padding-top: 1%;
  padding-bottom: 3%;
 width: 90%;
 margin-right: 0.15%;

}

.sorting-block h5{
  margin-bottom: 5%;
  font-weight: bold;
  font-size: 21pt;
  border-bottom: solid;
  padding-bottom: 3%;
  
}

.sorting-block li{
  list-style: none;
  border-bottom-style: solid;
  width: 80%;
  padding-bottom: 2%;
  border-bottom-width: 1px;
  margin-bottom: 2%;
}

.block-middle{
  padding-top: 5%!important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.block-middle-stelling{
  padding-top: 2%!important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.block-middle-stelling h5{
  font-weight: bolder;
  font-style: italic;
}

.block-middle-stelling-qsort{
  padding-top: 1%!important;
  padding-bottom: 2%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.block-middle-stelling-qsort h5{
  font-weight: bolder;
  font-style: italic;
}
.block-middle h5{
  font-weight: bolder;
}

.block-middle h6{
  text-align: center;
}
.block-middle-text{
  margin-top: 2%;
  text-align: center;
}

.block-middle-text p{
  margin-bottom: 3%;
  padding-left: 10%;
  padding-right: 10%;
}

.block-middle-text-question{
  margin-top: 2%;
  text-align: center;
}
.block-middle-text-question p{
  margin-bottom: 3%;
  padding-left: 10%;
  padding-right: 10%;
  font-size: 18pt;
}

.block-middle-text-left{
padding-top: 5%;
}

.disagrees-table{
  border-color: #ED6A1C;
  border-width: 3px;
}

.disagrees-table h5{
  border-color: #ED6A1C;
}

.naturals-table{
  border-color: #ED6A1C;
  border-width: 3px;
  background-color: #F2B28C;
  color: white;
}

.naturals-table h5{
  border-color: #ED6A1C;
  color: white;
}
.agrees-table{
  border-color: #ED6A1C;
  border-width: 3px;
  background-color: #ED6A1C;
  color: white;
}

.marginbottom{
  margin-bottom: 5%;
}

.agrees-table h5{
  border-color: white;
  color: white;
}


.image-check{
  display: flex;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.category{
  color: #ED6A1C;
  font-weight: bold;
  margin-left: 2%;
  margin-right: 2%;
}

.date-list{
  margin-left: 2%;
  margin-right: 2%;
}

.see-list{
  float: right;
  margin-right: 2%;
}

.see-list-delete i{
  float: right;
  margin-right: 1%;
  color:#FF0000;
  margin-top: 0.5%;
}

.see-list-edit i{
  float: right;
  margin-right: 1%;
  margin-top: 0.5%;
}

.question-list{
  margin-right: 2%;
}

.select-box-bord{
  width: 50%;
  background-color: white;
  border-radius: 0px!important;
  border-top: none;
  border-left: none;
  border-right: none;
  padding: 2%;
}



.nav-ffort .nav-item .nav-link.active, .nav-ffort .nav-item .nav-link:hover{
  border: none!important;

}

.active, .nav-link:hover{

border: none;
}

.active{
  background-color: #334B5F;
}

.custom-Class div{
  width: 100%!important;
}

.position-title{
  border-bottom-style: solid;
  border-bottom-width: 2px;

  margin-bottom: 5%;
}
.position-title h3{
  text-align: center;
  background-color: white;
  margin-bottom: 4%!important;
}

.position-positive div{
  background-color: #ED6A1C;
}


  .one{
    background-image: url('./images/background-1-groot.jpg');
    background-repeat: no-repeat;
    background-size: 90%;
    background-position: center; /* Center the image */
    height: 18vh;
  }
  
  .two{
    background-image: url('./images/background-2-groot.jpg');
    background-repeat: no-repeat;
    background-size: 90%;
    background-position: center; /* Center the image */
    height: 27vh;
  }
  
  .three{
    background-image: url('./images/background-3-groot.jpg');
    background-repeat: no-repeat;
    background-size: 90%;
    background-position: center; /* Center the image */
    height: 35vh;
  }

  .position-negative div{
    background-color: white!important;
    padding: 0px!important;
  
  }
  
  .position-negative div div{
    padding: 10px!important;
    border-color: #ED6A1F!important;
    border-style: solid;
    border-width: 2px;
    padding: 17px!important;
  }
  
  .position-natural div{
    background-color: #F2B28C;
    padding: 0px!important;
  }
  
  .position-natural div div{
    background-color: #F2B28C!important;
    color: white!important;
    padding: 17px!important;
    border-color: #ED6A1F!important;
    border-style: solid;
    border-width: 2px;
  }
  
  .position-positive div{
    background-color: #ED6A1F;
    padding: 0px!important;
  }
  
  .position-positive div div{
    background-color: #ED6A1F!important;
    color: white!important;
    padding: 17px!important;
    border-color: #ED6A1F!important;
    border-style: solid;
    border-width: 2px;
  }

  .marginTop label{
    margin-top: 2%;
  }
@media only screen and (max-width: 1280px) {
.one{
  background-image: url('./images/background-1.jpg');
  background-repeat: no-repeat;
  background-size: 90%;
  background-position: center; /* Center the image */
  height: 18vh;
}

.two{
  background-image: url('./images/background-2.jpg');
  background-repeat: no-repeat;
  background-size: 90%;
  background-position: center; /* Center the image */
  height: 27vh;
}

.three{
  background-image: url('./images/background-3.jpg');
  background-repeat: no-repeat;
  background-size: 90%;
  background-position: center; /* Center the image */
  height: 35vh;
}

.position-negative div{
  background-color: white!important;
  padding: 0px!important;

}

.position-negative div div{
  padding: 10px!important;
  border-color: #ED6A1F!important;
  border-style: solid;
  border-width: 2px;

}

.position-natural div{
  background-color: #F2B28C;
  padding: 0px!important;
}

.position-natural div div{
  background-color: #F2B28C!important;
  color: white!important;
  padding: 10px!important;
  border-color: #ED6A1F!important;
  border-style: solid;
  border-width: 2px;
}

.position-positive div{
  background-color: #ED6A1F;
  padding: 0px!important;
}

.position-positive div div{
  background-color: #ED6A1F!important;
  color: white!important;
  padding: 10px!important;
  border-color: #ED6A1F!important;
  border-style: solid;
  border-width: 2px;
}

.login-block-left{
  background-color: white;
  border-left-style: solid;
  border-top-style: solid;
  border-bottom-style: solid;
  border-right-style: none;
  border-width: 1px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  margin-top: 5%;
  padding-bottom: 3%;
  padding-top: 2.5%;
}
.login-block-right{
  background-color: white;
  background-image: url('./images/login-image.png');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center; /* Center the image */
  border-right-style: solid;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: none;
  border-width: 1px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  margin-top: 5%;
  padding-bottom: 5%;
  display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
}
}



.class-group-natural{
  background-color: #F2B28C;
  width: 60%;
  margin-right: 5%;
  margin-left: 1%;
  border-radius: 20px;
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 2%;
  padding-bottom: 2%;
  border-color: #ED6A1C;
  border-width: 2px;
  border-style: solid;
}

.class-group-natural h4,.class-group-agree h4{
  color: white!important;
  font-weight: bold;
  margin-top: -2%;
}

.class-group-disagree h4{
  font-weight: bold;
  margin-top: -2%;
}

.class-group-natural div{
  background-color: #F2B28C!important;
  padding: 0px!important;
}

.class-group-natural div{
  background-color: none!important;
  width: 100%!important;
}

.class-group-natural div div{
  background-color: #F2B28C!important;
  padding: 3px!important;
  border-bottom-style: solid!important;
  border-bottom-width: 1px!important;
  border-bottom-color: white!important;
  color: white!important;
  max-width: 450px;
}

.class-group-disagree{
  background-color: white;
  width: 60%;
  margin-right: 5%;
  margin-left: 5%;
  border-radius: 20px;
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 2%;
  padding-bottom: 2%;
  border-color: #F2B28C;
  border-width: 2px;
  border-style: solid;
}

.class-group-disagree div{
  background-color: white!important;
  width: 100%!important;
  padding: 0px!important;
}

.class-group-disagree div div{
  background-color: white!important;
  padding: 3px!important;
  border-bottom-style: solid!important;
  border-bottom-width: 1px!important;
  max-width: 450px;
}

.class-group-agree{
  background-color: #ED6A1C;
  width: 60%;
  margin-right: 5%;
  margin-left: 1%;
  border-radius: 20px;
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 2%;
  padding-bottom: 2%;
  border-color: #ED6A1C;
  border-width: 2px;
  border-style: solid;
}

.class-group-agree div{
  background-color: #ED6A1C!important;
  width: 100%!important;
}

.class-group-agree div div{
  background-color: #ED6A1C!important;
  padding: 3px!important;
  border-bottom-style: solid!important;
  border-bottom-width: 1px!important;
  color: white!important;
  max-width: 450px;
}


.background-white{
  background-color: white!important;
}

.Drops{
  margin-top: 5%;
  margin-left: 6%!important;
  width: 100%;
  margin-right: 8%;
}

.qsortdone{
  border-style: solid;
  border-color: black;
  border-width: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1%;
  border-radius: 50px;
  margin-top: 5%;
}

.qsortdone button{
  width: 100%!important;
  padding: 3%!important;
  margin-left: 2%!important;
  margin-right: 2%!important;
}

.button-sortdone{
  background-color: #ED6A1C!important;
    border-radius: 50px!important;
    padding: 2% 8%!important;

    font-weight: bold!important;
    border-color: #ED6A1C!important;
    
}

.button-restart{
  background-color: white!important;
    border-radius: 50px!important;
    padding: 2% 8%!important;
    color: #757575!important;
    font-weight: bold!important;
    border-color: #ED6A1F!important;
    
}

.shopping-list{
  background-color: white!important;
}

.loading-dots{
  width: 6%;
  margin-left: 47%;
}

.settings-chaskdesk, .overview-chaskdesk{
  background-color: white;
  padding: 3%!important;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  border-radius: 5px;
  padding-right: 5%;

}

.overview-chaskdesk{
  margin-top: 5%;
}

.paymentmethod-chaskdesk{
  background-color: #FCFCFC!important;
  padding: 3%!important;
  margin-top: 5%;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.paymentmethod-buttons button{
  margin-bottom: 10%;
  margin-top: 5%;
  margin-left: 30%;
}

.paymentmethod-chaskdesk h2, .paymentmethod-chaskdesk p,.paymentmethod-chaskdesk h3{
  text-align: center;
}

.paymentmethod-chaskdesk h3{
  margin-top: 2%;
}

.celebration-bar{
  background-color: #334B5F;
  width:100%;
  bottom: 0;
  margin-bottom: 20%;
  color: white;
  padding: 1% 2%;
  border-radius: 5px;
  position: relative;
  opacity: 1;
  animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration:0.5s;
}

@keyframes fadeInOpacity {
	0% {
		margin-top: 30%;
	}
	100% {
    margin-top: 20%;
	}
}

.paymentmethod-chaskdesk p{
  padding: 4% 5%
}

.list-treatments li{
  list-style: none;

}
.list-treatments p{
  color:white;
}

.list-amount{
  float: right;

}

.list-amount input, .list-price input{
width: 100%;
  border-style: none;
  padding:2% 4%;
  text-align: center;
}

.list-price{
  float: right;

}

.list-action{
  float: right;
  color: red;
  margin-top: 0.5%;
}


